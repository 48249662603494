import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// MUI :
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { Visibility, AccountCircle, VisibilityOff } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";

import Slide from "react-reveal/Slide";

import AuthService from "../../../services/auth.service";

// CSS :
import "./Login.scss";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const enteringFormData = (event) => {
    let { name, value, type, checked } = event.target;
    let newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };
  let data = { email: formData.email, password: formData.password };

  const handleLogin = (event) => {
    event.preventDefault();
    AuthService.login(data, dispatch, navigate);
  };

  return (
    <Slide top>
      <div className="loginContainer">
        <div className="loginForm">
          <div className="welcomeBox">
            <h1>Welcome Back!</h1>
            <p>Please enter your login credentials to access your dashboard</p>
          </div>
          <Box
            component="form"
            autoComplete="off"
            className="formBox"
            onSubmit={handleLogin}>
            <TextField
              autoFocus
              required
              id="outlined-required"
              label="Email"
              color="success"
              name="email"
              value={formData.email}
              onChange={enteringFormData}
              sx={{ m: 1, width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              id="outlined-required"
              label="Password"
              color="success"
              name="password"
              value={formData.password}
              onChange={enteringFormData}
              type={showPassword ? "text" : "password"}
              sx={{ m: 1, width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <div className="loginButton">
              {/* <Button
                style={{ color: "var(--primaryColor)" }}
                disabled
              >
                sign up
              </Button> */}
              <Button variant="contained" type="submit" color="success">
                {loading === true ? (
                  <CircularProgress
                    color="info"
                    style={{
                      height: "20px",
                      width: "20px",
                      margin: "0 15px",
                    }}
                  />
                ) : (
                  "Login"
                )}
              </Button>
            </div>
          </Box>
        </div>
      </div>
    </Slide>
  );
};

export default Login;
