// ANT-D :
import { useForm } from "antd/es/form/Form";
import { Button, Col, Upload, Flex, Form, Input, Row, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import "./ClientForm.scss";
import ImageUploader from "../../../../Components/ImageUploader";
import { useState } from "react";
import AuthService from "../../../../services/auth.service";
import { useDispatch, useSelector } from "react-redux";
const { Option } = Select;
const { TextArea } = Input;

const ClientForm = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const [imageFile, setImageFile] = useState();

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("title", values.title);
    formData.append("detail", values.detail);
    formData.append("country", values.country);
    formData.append("location", values.location);

    let [success, error] = await AuthService.AddJob(formData, dispatch);
    if (success) {
      form.resetFields();
      setImageFile("");
    }
  };

  const handleCancel = () => {
    form.resetFields();
  };

  return (
    <div className="client-form-container">
      <h2>Create Job</h2>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={handleSubmit}
        className="issue-form-container"
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label="Upload Image" name="image">
              <ImageUploader
                imageFile={imageFile}
                setImageFile={setImageFile}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              label="Job Title"
              name="title"
              rules={[{ required: true }]}
            >
              <Input size="large" placeholder="Enter Job Title" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true }]}
            >
              <Input type="text" size="large" placeholder="Enter Country" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              label="Location"
              name="location"
              rules={[{ required: true }]}
            >
              <Input type="text" size="large" placeholder="Enter Location" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="detail">
              <TextArea
                placeholder="Description"
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex gap={15} style={{ marginTop: "10px" }}>
          <Button
            size="large"
            type="primary"
            loading={loading}
            htmlType="submit"
            className="primary-btn"
          >
            Create
          </Button>
          <Button size="large" onClick={handleCancel}>
            Cancel
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

export default ClientForm;
