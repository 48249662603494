import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// MUI
import { ThemeProvider } from "@mui/material/styles";

// Components :
import theme from "./theme";
import Login from "./Pages/Auth/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";

// Helpers :
import { ToastContainer } from "react-toastify";

// CSS :
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ user, children }) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

const App = () => {
  const { user } = useSelector((state) => state.auth);

  let token = localStorage.getItem("token");
  let AuthToken = token ?? null;

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Routes>
        <Route path="login" element={<Login />} />
        <Route
          path="dashboard/*"
          element={
            <ProtectedRoute user={AuthToken}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
