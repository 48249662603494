import React, { useEffect, useState } from "react";

import "./SmartHome.scss";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../../../services/auth.service";
import Cards from "./Components/Cards/Cards";

const Dashboard = () => {
  const dispatch = useDispatch();
  const getAllSetting = useSelector((state) => state.auth.settings.data);

  // useEffect(() => {
  //   AuthService.getAllSetting(dispatch);
  // }, []);

  return (
    <>
      <div>
        <div className="smart-home-container">
          <h4 className="heading">Coming Soon</h4>
          <div className="flex-card">
            {getAllSetting &&
              getAllSetting.map((data, index) => {
                return <Cards index={index} data={data} />;
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
