import { createTheme } from "@mui/material/styles";

// A custom theme for this app

const theme = createTheme({
  status: {
    success: {
      color: "rgba(84, 214, 44, 0.16)",
      textContrast: "rgb(34, 154, 22)",
    },
    warning: {
      color: "rgba(255, 193, 7, 0.16)",
      textContrast: "rgb(183, 129, 3)",
    },
  },
  customColor: {
    secondary: {
      color: "rgba(0, 0, 0, 0.53)",
    },
  },
  palette: {
    // primary: {
    // 	light: "#4b525a",
    // 	main: "#FECC4C",
    // 	dark: "#050608",
    // 	// contrastText: "white",
    // },
    // secondary: {
    // 	light: "#fffadd",
    // 	main: "#f5d312",
    // 	dark: "#e7c400",
    // 	// contrastText: "rgb(2, 136, 209)",
    // },
    primary: {
      light: "#4b525a",
      main: "#1e2731",
      dark: "#050608",
      // contrastText: "rgb(2, 136, 209)",
    },
    secondary: {
      light: "rgb(240, 249, 255)",
      main: "rgb(240, 249, 255)",
      dark: "rgb(240, 249, 255)",
      contrastText: "#26619C",
    },
    success: {
      main: "var(--primaryColor)",
      light: "var(--primaryColor)",
      dark: "var(--primaryColor)",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "rgb(183, 129, 3)",
      light: "rgba(255, 193, 7, 0.16)",
      dark: "#e65100",
      // contrastText: "rgb(183, 129, 3)"
    },
    error: {
      main: "#FC5B5B",
      light: "#FC5B5B",
      dark: "#FC5B5B",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#F3F2EE",
      // light: "rgb(2, 136, 209, 0.16)",
      // dark: "#01579b",
      // contrastText: "rgb(2, 136, 209)",
    },
  },
  typography: {
    // fontFamily: "Open Sans', sans-serif",
  },
});

export default theme;