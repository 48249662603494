import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { HiOutlineMenuAlt3 } from "react-icons/hi";

import { Popover } from "antd";

import { useDispatch, useSelector } from "react-redux";

import logo from "../../../../Assets/Navbar/logo.svg";
import greater from "../../../../Assets/Navbar/greater.svg";
import profile from "../../../../Assets/images//profile.png";
import { ReactComponent as Setting } from "../../../../Assets/icons/setting.svg";

import "./Navbar.scss";

const Navbar = () => {
  const dispatch = useDispatch();

  const [userData, setUserData] = useState();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserData(user);
  }, [dispatch]);

  const handleLogout = () => {
    localStorage.clear();
    toast.warn("Logout Success");
    setTimeout(() => {
      window.location.href = "/";
    }, 500);
  };

  const text = (
    <div className="d-title">
      <div className="name">
        {userData?.firstName} {userData?.lastName}
      </div>
      <div className="name">{userData?.role}</div>
    </div>
  );

  const content = (
    <>
      <div className="d-bio">
        <div className="border"></div>
        <div className="flexLine cursor" onClick={handleLogout}>
          <Setting className="icon" />
          Logout
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="navContainer">
        <div className="shadow"></div>
        <div className="navbar">
          <div className="flex-nav">
            <div className="left-nav">
              <div className="logo">
                <Link to="/dashboard">
                  <img src={logo} alt="" />
                </Link>
                <strong className="heading">Quality Recruiter</strong>
              </div>
              <div className="mobile-menu">
                <HiOutlineMenuAlt3 className="icon" />
              </div>
            </div>
            <div className="right-nav">
              <div className="sub-menu">
                <p>Application</p>
                <div className="img">
                  <img src={greater} alt="" />
                </div>
                <p>Dashboard</p>
              </div>
              <div className="bio">
                <div className="nav-popover">
                  <Popover
                    placement="bottomRight"
                    title={text}
                    content={content}
                    trigger="click">
                    <div className="img">
                      <img src={profile} alt="ERROR" />
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
