import { useEffect, useState } from "react";
import { MdCalendarMonth } from "react-icons/md";

// ANT-D :
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ExclamationCircleFilled } from "@ant-design/icons";

import {
  Avatar,
  Button,
  Checkbox,
  Flex,
  Space,
  Table,
  Modal,
  TableProps,
  Tag,
} from "antd";

// Helpers :
import dayjs from "dayjs";

// CSS :
import "./ClientTable.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../../../services/auth.service";

const { confirm } = Modal;
const IssueList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allJobs, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    AuthService.GetAllJobs(dispatch);
  }, [dispatch]);

  const title = () => <div style={{ paddingLeft: "20px" }}>Image</div>;
  const columns = [
    {
      width: "100px",
      title: title,
      dataIndex: "file",
      key: "file",
      render: (text, record) => {
        return (
          <div className="avatar-box">
            <Avatar src={record?.image?.Location || ""} size={40} />
          </div>
        );
      },
    },
    {
      width: "250px",
      title: "Job Title",
      dataIndex: "title",
      key: "title",
    },
    {
      width: "150px",
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      width: "150px",
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      width: "150px",
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });
        };
        return <>{formatDate(text)}</>;
      },
    },
    {
      width: "400px",
      title: "Description",
      dataIndex: "detail",
      key: "detail",
      render: (text, record) => (
        <div>{text.length > 100 ? `${text.slice(0, 100)}...` : text}</div>
      ),
    },
    {
      width: "100px",
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <Button
            type="link"
            icon={<EditOutlined />}
            // onClick={() => navigate(`/dashboard/edit-job/${record?._id}`)}
            onClick={() =>
              navigate(`/dashboard/edit-job/${record?._id}`, {
                state: { id: record?._id },
              })
            }
          />
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record?._id)}
          />
        </div>
      ),
    },
  ];

  const handleDelete = (id) => {
    confirm({
      title: "Confirmation Message",
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this Job?",
      okText: "Yes",
      onOk() {
        AuthService.deleteJob(dispatch, id);
      },
      onCancel() {},
    });
  };

  return (
    <div className="client-table-container">
      {/* <div className="add-job">
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          className="primary-btn"
          onClick={() => navigate("/dashboard/create-jobs")}>
          Create new Job
        </Button>
      </div> */}
      <h2>View Jobs</h2>
      <Table
        size="middle"
        // pagination={false}
        columns={columns}
        loading={loading}
        dataSource={allJobs}
        className="issue-list-table"
        scroll={{ x: true, y: "calc(100vh - 260px)" }}
      />
    </div>
  );
};

export default IssueList;
