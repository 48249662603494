import React, { useState } from "react";
import { FaUserCheck } from "react-icons/fa";
import { Route, Routes, useNavigate } from "react-router-dom";

// ANT-D || MUI :
import { Layout, Menu } from "antd";

// Components :
import Navbar from "./Components/Navbar/Navbar";
import Weather from "./Components/Weather/Weather";
import GoogleMaps from "./Components/GoogleMaps/GoogleMaps";
import SmartHome from "./Components/SmartHome/SmartHome";

// ASSETS || ICONS :
import { FaMapMarkerAlt } from "react-icons/fa";
import { RiHomeSmileFill } from "react-icons/ri";
import { BsFillCloudDrizzleFill } from "react-icons/bs";

// CSS :
import "./Dashboard.scss";
import ClientTable from "./Components/ClientTable/ClientTable";
import ClientForm from "./Components/ClientForm/ClientForm";
import { useSelector } from "react-redux";
import JobEditForm from "./Components/JobEditForm/JobEditForm";
import ViewEvents from "./Components/ViewEvents/ViewEvents";
import CreateEvents from "./Components/CreateEvents/CreateEvents";
import EditEvents from "./Components/EditEvents/EditEvents";
import ViewApplications from "./Components/ViewApplications/ViewApplications";
import ViewContactRequests from "./Components/ViewContactRequests/ViewContactRequests";

const { Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const currentRoutes = [
  getItem("Dashboard", "/", <RiHomeSmileFill />),
  getItem("View Jobs", "/view-jobs", <FaUserCheck />),
  getItem("Create Jobs", "/create-jobs", <FaUserCheck />),
  getItem("View Events", "/view-events", <FaUserCheck />),
  getItem("Create Events", "/create-events", <FaUserCheck />),
  getItem("View Applications", "/view-application", <FaUserCheck />),
  getItem("View Contact Requests", "/contact-us-requests", <FaUserCheck />),
  // getItem("Client Table", "/client-table", <FaUserCheck />),
  // getItem("Maps", "googleMaps", <FaMapMarkerAlt />),
  // getItem('Weather', 'weather', <BsFillCloudDrizzleFill />),
];

const Dashboard = () => {
  let Navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(false);

  const handleMenuClick = (e) => {
    if (e.key === "/") {
      Navigate("/dashboard");
    } else if (e.key === "/view-jobs") {
      Navigate("view-jobs");
    } else if (e.key === "/create-jobs") {
      Navigate("create-jobs");
    } else if (e.key === "/view-events") {
      Navigate("view-events");
    } else if (e.key === "/contact-us-requests") {
      Navigate("contact-us-requests");
    } else if (e.key === "/client-form") {
      Navigate("client-form");
    } else if (e.key === "/view-application") {
      Navigate("view-application");
    } else if (e.key === "googleMaps") {
      Navigate("googleMaps");
    } else if (e.key === "weather") {
      Navigate("weather");
    } else if (e.key === "/create-events") {
      Navigate("create-events");
    }
  };

  return (
    <>
      <Navbar />
      <div className="sidebar-container">
        {/* ***** sidebar menu ********** */}
        <Sider
          className="sider"
          width="250px"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}>
          <Menu
            mode="inline"
            items={currentRoutes}
            onClick={handleMenuClick}
            defaultSelectedKeys={["/"]}
          />
        </Sider>
        {/* ***** All Pages will render here ********** */}
        <div className="right-pages">
          <Routes>
            <Route path="/" element={<SmartHome />} />
            <Route path="view-jobs" element={<ClientTable />} />
            <Route path="create-jobs" element={<ClientForm />} />
            <Route path="edit-job/*" element={<JobEditForm />} />
            <Route path="view-events" element={<ViewEvents />} />
            <Route path="create-events" element={<CreateEvents />} />
            <Route path="edit-events/*" element={<EditEvents />} />
            <Route path="view-application" element={<ViewApplications />} />
            <Route
              path="contact-us-requests"
              element={<ViewContactRequests />}
            />
            <Route path="googleMaps" element={<GoogleMaps />} />
            <Route path="weather" element={<Weather />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
