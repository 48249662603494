import React, { useState } from 'react'
import { Switch } from "antd";
import { FaLightbulb } from 'react-icons/fa';
import AuthService from '../../../../../../services/auth.service';
import { useDispatch, useSelector } from "react-redux";
import './Cards.scss'


const Cards = ({ data }) => {
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();

    const data1 = useSelector((state) => state.auth.settings.data)


    const handleChange = () => {
        AuthService.upDateSetting(data1, dispatch)

        setIsChecked(!isChecked);
    };
    return (
        <div className="smart-card">
            {/* <div className="place">TV Launch</div> */}
            <div className="place">{data.Device_ID}</div>
            <div className="place">{data.MQTT_ID}</div>
            <FaLightbulb
                style={{
                    width: "100%",
                    fontSize: "40px",
                    textAlign: "center",
                    color: isChecked ? "#df7401" : "black"
                }} />
            <div>
                <div className="toggle-button">
                    {!isChecked ?
                        <>
                            Turn On :
                        </> :
                        <>
                            Turn Off :
                        </>
                    }
                    <Switch size="medium" onChange={handleChange} />
                </div>
            </div>
        </div>
    )
}

export default Cards
