import { useEffect, useState } from "react";
import { MdCalendarMonth } from "react-icons/md";

// ANT-D :
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ExclamationCircleFilled } from "@ant-design/icons";

import {
  Avatar,
  Button,
  Checkbox,
  Flex,
  Space,
  Table,
  Modal,
  TableProps,
  Tag,
} from "antd";

// Helpers :
import dayjs from "dayjs";

// CSS :
import "./ViewApplications.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../../../services/auth.service";

const { confirm } = Modal;
const ViewApplications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allApplications, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    AuthService.GetAllApplications(dispatch);
  }, [dispatch]);

  const columns = [
    {
      width: "5px",
      title: "",
      dataIndex: "",
      key: "",
    },
    {
      width: "150px",
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => (
        <div>
          {record?.firstName} {record?.lastName}
        </div>
      ),
    },

    {
      width: "100px",
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      width: "150px",
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      width: "100px",
      title: "Location",
      dataIndex: "location",
      key: "location",
    },

    {
      width: "80px",
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      width: "120px",
      title: "Phone No",
      dataIndex: "phoneNo",
      key: "phoneNo",
    },
    {
      width: "100px",
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => {
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          });
        };
        return <>{formatDate(text)}</>;
      },
    },
    {
      width: "100px",
      title: "Download CV",
      dataIndex: "file",
      key: "file",
      render: (text, record) => {
        console.log("------>", record);
        return (
          <>
            {record?.cv?.Location && (
              <a
                href={record?.cv?.Location}
                download={record?.cv?.key}
                target="_blank" // Optional: Opens in a new tab for better user experience
                rel="noopener noreferrer">
                <Button variant="contained" color="primary" component="span">
                  Download File
                </Button>
              </a>
            )}
          </>
        );
      },
    },
    {
      width: "70px",
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          {/* <Button
            type="link"
            icon={<EditOutlined />}
            // onClick={() => navigate(`/dashboard/edit-job/${record?._id}`)}
            onClick={() =>
              navigate(`/dashboard/edit-events/${record?._id}`, {
                state: { id: record?._id },
              })
            }
          /> */}
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record?._id)}
          />
        </div>
      ),
    },
  ];

  const handleDelete = (id) => {
    confirm({
      title: "Confirmation Message",
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this Job?",
      okText: "Yes",
      onOk() {
        AuthService.DeleteApplication(dispatch, id);
      },
      onCancel() {},
    });
  };

  return (
    <div className="view-events-container">
      <h2>View Applications</h2>
      <Table
        size="middle"
        // pagination={false}
        columns={columns}
        loading={loading}
        dataSource={allApplications}
        className="issue-list-table"
        scroll={{ x: true, y: "calc(100vh - 260px)" }}
      />
    </div>
  );
};

export default ViewApplications;
