import { useEffect, useState } from "react";
import { MdCalendarMonth } from "react-icons/md";

// ANT-D :
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { ExclamationCircleFilled } from "@ant-design/icons";

import {
  Avatar,
  Button,
  Checkbox,
  Flex,
  Space,
  Table,
  Modal,
  TableProps,
  Tag,
} from "antd";

// Helpers :
import dayjs from "dayjs";

// CSS :
// import "./ViewApplications.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../../../services/auth.service";

const { confirm } = Modal;
const ViewContactRequests = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allContactRequest, loading } = useSelector((state) => state.auth);

  useEffect(() => {
    AuthService.GetAllContactRequests(dispatch);
  }, [dispatch]);

  const columns = [
    {
      width: "5px",
      title: " ",
      dataIndex: "file",
      key: "file",
    },
    {
      width: "250px",
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      width: "150px",
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      width: "150px",
      title: "Phone No",
      dataIndex: "phoneNo",
      key: "phoneNo",
    },
    {
      width: "150px",
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      width: "400px",
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text, record) => (
        <div>{text}</div>
        // <div>{text.length > 100 ? `${text.slice(0, 100)}...` : text}</div>
      ),
    },
    {
      width: "70px",
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          {/* <Button
            type="link"
            icon={<EditOutlined />}
            // onClick={() => navigate(`/dashboard/edit-job/${record?._id}`)}
            onClick={() =>
              navigate(`/dashboard/edit-events/${record?._id}`, {
                state: { id: record?._id },
              })
            }
          /> */}
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record?._id)}
          />
        </div>
      ),
    },
  ];
  const handleDelete = (id) => {
    confirm({
      title: "Confirmation Message",
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this Job?",
      okText: "Yes",
      onOk() {
        AuthService.DeleteContactRequest(dispatch, id);
      },
      onCancel() {},
    });
  };
  return (
    <div className="view-events-container">
      <h2>View Contact Request</h2>
      <Table
        size="middle"
        // pagination={false}
        columns={columns}
        loading={loading}
        dataSource={allContactRequest}
        className="issue-list-table"
        scroll={{ x: true, y: "calc(100vh - 260px)" }}
      />
    </div>
  );
};

export default ViewContactRequests;
