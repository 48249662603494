import authReducer from './slices/loginSlice'
const { combineReducers } = require("@reduxjs/toolkit");




const rootReducer = combineReducers({
   
    auth: authReducer
});


export default rootReducer;