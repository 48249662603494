// ANT-D :
import { useForm } from "antd/es/form/Form";
import { Button, Col, Upload, Flex, Form, Input, Row, Select } from "antd";

import ImageUploader from "../../../../Components/ImageUploader";
import { useEffect, useState } from "react";
import AuthService from "../../../../services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import moment from "moment";
const { TextArea } = Input;

const EditEvents = () => {
  const [form] = useForm();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const { getEventDetails, loading } = useSelector((state) => state.auth);

  const [imageFile, setImageFile] = useState();

  useEffect(() => {
    const id = location?.state?.id;
    AuthService.GetEventById(dispatch, id);
  }, [dispatch]);

  useEffect(() => {
    if (getEventDetails) {
      const formattedDetails = {
        ...getEventDetails,
        date: getEventDetails.date ? moment(getEventDetails.date) : null,
      };

      form.setFieldsValue(formattedDetails);
    }
  }, [getEventDetails, form]);

  const handleSubmit = async (values) => {
    const id = location?.state?.id;

    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("date", values.date);
    formData.append("title", values.title);
    formData.append("DateAndTime", values.DateAndTime);
    formData.append("detail", values.detail);
    formData.append("country", values.country);
    formData.append("location", values.location);

    let [success, error] = await AuthService.UpdateEvent(
      dispatch,
      formData,
      id
    );
    if (success) {
      navigate("/dashboard/view-events");
    }
  };

  const handleCancel = () => {
    form.resetFields();
  };

  return (
    <div className="events-form-container">
      <h2>Edit Events</h2>
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={handleSubmit}
        className="events-form-card">
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item label="Upload Image" name="image">
              <ImageUploader
                imageFile={imageFile}
                setImageFile={setImageFile}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              label="Event Title"
              name="title"
              rules={[{ required: true }]}>
              <Input size="large" placeholder="Enter Job Title" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              label="Event Date and Time"
              name="date"
              rules={[{ required: true }]}>
              <DatePicker
                size="large"
                showTime
                placeholder="Select date and time"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true }]}>
              <Input type="text" size="large" placeholder="Enter Country" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <Form.Item
              label="Location"
              name="location"
              rules={[{ required: true }]}>
              <Input type="text" size="large" placeholder="Enter Location" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="detail">
              <TextArea
                placeholder="Description"
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Flex gap={15} style={{ marginTop: "10px" }}>
          <Button
            size="large"
            type="primary"
            loading={loading}
            htmlType="submit"
            className="primary-btn">
            Update
          </Button>
          <Button size="large" onClick={handleCancel}>
            Cancel
          </Button>
        </Flex>
      </Form>
    </div>
  );
};

export default EditEvents;
