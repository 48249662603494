import React, { useEffect, useState } from "react";
import axios from "axios";

import "./Weather.scss";

import { AiOutlineSearch } from "react-icons/ai";
import { FaTemperatureHigh } from "react-icons/fa";
import { BsFillCloudsFill } from "react-icons/bs";
import { MdOutlineAir } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

import WaterIcon from "@mui/icons-material/Water";


const Weather = () => {
  const [userData, setUserData] = useState({
    temp: 0,
    city: "",
    cloudpct: 0,
    feelLike: 0,
    humidity: 0,
    windSpeed: 0,
    sunrise: 0,
    sunset: 0,
    minTemp: 0,
    maxTemp: 0,
  });

  const [city, setCity] = useState("kasur");
  const [isLoading, setIsLoading] = useState(false);
  const [Iserror, setIsError] = useState(null);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    if (!city) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://weather-by-api-ninjas.p.rapidapi.com/v1/weather?city=${city}`,
        {
          headers: {
            "X-RapidAPI-Key":
              "3ba98a1ad6mshe54184633b03e38p1a2fbajsnd73512dfac89",
            "X-RapidAPI-Host": "weather-by-api-ninjas.p.rapidapi.com",
          },
        }
      );

      setUserData({
        temp: response.data.temp,
        city: response.data.city,
        cloudpct: response.data.cloud_pct,
        feelLike: response.data.feels_like,
        humidity: response.data.humidity,
        windSpeed: response.data.wind_speed,
        sunrise: response.data.sunrise,
        sunset: response.data.sunset,
        minTemp: response.data.min_temp,
        maxTemp: response.data.max_temp,
      });

      setIsError(null);
      setIsLoading(false);
    } catch (error) {
      setIsError("Please enter Valid City Name!");
      setIsLoading(false);
    }
  };

  const visibleClick = () => {
    setVisible(!visible);
  };

  return (
    <>
      <h3>Weather</h3>
      <div className="main-container">
        <form onSubmit={(e) => e.preventDefault()}>
          <h2 style={{ textAlign: "center" }}>Enter your City Name:</h2>
          <div className="form">
            <input
              type="text"
              placeholder="City Name"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <button onClick={fetchData}>
              <AiOutlineSearch />
            </button>
          </div>
        </form>

        {isLoading ? (
          <div className="loading">
            <h1>Loading...</h1>
          </div>
        ) : Iserror ? (

          <div className="error-container">
            <h1>Error!</h1>
            <p>{Iserror}</p>
          </div>

        ) : (
          <>
            <div className="main-box">
              <h1 style={{ textAlign: "center" }}>
                City: <span> {city}</span>
              </h1>
              <h1 style={{ textAlign: "center" }}>
                <FaTemperatureHigh />
                Temperature: <span> {userData.temp}°C</span>
              </h1>
            </div>

            <div className="main-card">
              <h2>Weather Detail:</h2>
              <div>
                <div className="card-items">
                  <h5>
                    <BsFillCloudsFill />
                    Cloud Percentage:
                  </h5>
                  <h5>
                    <FaTemperatureHigh />
                    Feels Like:
                  </h5>
                  <h5>
                    <WaterIcon />
                    Humidity:
                  </h5>
                  <h5>
                    <MdOutlineAir />
                    Wind Speed:
                  </h5>
                  <h5>
                    <FaTemperatureHigh />
                    Minimum Temperature:
                  </h5>
                  <h5>
                    <FaTemperatureHigh />
                    Maximum Temperature:
                  </h5>
                </div>
                <div className="card-items">
                  <h5>{userData.cloudpct} %</h5>
                  <h5>{userData.feelLike} °C</h5>
                  <h5>{userData.humidity} %</h5>
                  <h5>{Math.round(userData.windSpeed)} MPH</h5>
                  <h5>{userData.minTemp} °C</h5>
                  <h5>{userData.maxTemp} °C</h5>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Weather;
