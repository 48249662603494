import React from "react";

// CSS :
import './GoogleMaps.scss'





function GoogleMaps() {
  return (

    <div className="googleMapsContainer">
      <div className="heading">
        <h2>Google Maps</h2>
      </div>
      <iframe
        title="Google Map"
        width="100%"
        height="100%"
        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54649.93807907335!2d74.41155021786665!3d31.120260694682663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919b97b6553a0cd%3A0x9306801f64aa8ed2!2sKasur%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1680716772921!5m2!1sen!2s`}
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default GoogleMaps;
