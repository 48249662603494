import http from "./http.service";
import { toast } from "react-toastify";
import Promisable from "./promisable.service";
import { authActions } from "./../redux/slices/loginSlice";

const AuthService = {
  login: async (data, dispatch, navigate) => {
    dispatch?.(authActions.setLoading(true));
    const [success, error] = await Promisable.asPromise(
      http.post(`api/auth/login`, data)
    );

    if (success) {
      const data = success.data.result;
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data));
      dispatch?.(authActions.setUser(data));

      dispatch?.(authActions.setLoading(false));
      toast.success("Login Successfully");
      navigate("/dashboard");
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  AddJob: async (data, dispatch) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.post(`api/job/add`, data)
    );

    if (success) {
      dispatch?.(authActions.setLoading(false));
      toast.success("Job created Successfully");
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  UpdateJob: async (dispatch, formData, id) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.patch(`api/job/${id}`, formData)
    );

    if (success) {
      dispatch?.(authActions.setLoading(false));
      toast.success("Job updated Successfully");
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  GetAllJobs: async (dispatch) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.get(`api/job/get`)
    );

    if (success) {
      const data = success.data.result;
      dispatch?.(authActions.setAllJobs(data));
      dispatch?.(authActions.setLoading(false));
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  GetJobById: async (dispatch, id) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.get(`api/job/${id}`)
    );

    if (success) {
      const data = success.data.result;
      dispatch?.(authActions.setGetJobDetails(data));
      dispatch?.(authActions.setLoading(false));
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },

  deleteJob: async (dispatch, id) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.delete(`api/job/${id}`)
    );

    if (success) {
      dispatch?.(authActions.setLoading(false));
      dispatch?.(authActions.removeJobs(id));
      toast.success("Job deleted successfully");
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  AddEvent: async (data, dispatch) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.post(`api/event/add`, data)
    );

    if (success) {
      dispatch?.(authActions.setLoading(false));
      toast.success("Event created Successfully");
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  GetAllEvents: async (dispatch) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.get(`api/event/get`)
    );

    if (success) {
      const data = success.data.result;
      dispatch?.(authActions.setAllEvents(data));
      dispatch?.(authActions.setLoading(false));
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  UpdateEvent: async (dispatch, formData, id) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.patch(`api/event/${id}`, formData)
    );

    if (success) {
      dispatch?.(authActions.setLoading(false));
      toast.success("Event updated Successfully");
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  GetEventById: async (dispatch, id) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.get(`api/event/${id}`)
    );

    if (success) {
      const data = success.data.result;
      dispatch?.(authActions.setGetEventDetails(data));
      dispatch?.(authActions.setLoading(false));
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  DeleteEvent: async (dispatch, id) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.delete(`api/event/${id}`)
    );

    if (success) {
      dispatch?.(authActions.setLoading(false));
      dispatch?.(authActions.removeEvents(id));
      toast.success("Event deleted successfully");
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },

  GetAllContactRequests: async (dispatch) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.get(`api/contactUs/get`)
    );

    if (success) {
      const data = success.data.result;
      dispatch?.(authActions.setAllContactRequest(data));
      dispatch?.(authActions.setLoading(false));
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  DeleteContactRequest: async (dispatch, id) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.delete(`api/contactUs/${id}`)
    );

    if (success) {
      dispatch?.(authActions.setLoading(false));
      dispatch?.(authActions.removeContacts(id));
      toast.success("Contact Request deleted successfully");
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },

  GetAllApplications: async (dispatch) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.get(`api/apply/get`)
    );

    if (success) {
      const data = success.data.result;
      dispatch?.(authActions.setAllApplications(data));
      dispatch?.(authActions.setLoading(false));
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  DeleteApplication: async (dispatch, id) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.delete(`api/apply/${id}`)
    );

    if (success) {
      dispatch?.(authActions.setLoading(false));
      dispatch?.(authActions.removeApplication(id));
      toast.success("Application deleted successfully");
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },

  logout: () => {
    localStorage.removeItem("token");
  },
};

export default AuthService;
