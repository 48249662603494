import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  allJobs: [],
  allEvents: [],
  allContactRequest: [],
  allApplications: [],
  getJobDetails: {},
  getEventDetails: {},
  loading: false,
  settings: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {},
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setAllJobs: (state, action) => {
      state.allJobs = action.payload;
    },
    setAllEvents: (state, action) => {
      state.allEvents = action.payload;
    },
    setAllContactRequest: (state, action) => {
      state.allContactRequest = action.payload;
    },
    setAllApplications: (state, action) => {
      state.allApplications = action.payload;
    },
    setGetJobDetails: (state, action) => {
      state.getJobDetails = action.payload;
    },
    setGetEventDetails: (state, action) => {
      state.getEventDetails = action.payload;
    },
    removeJobs: (state, action) => {
      const id = action.payload;
      state.allJobs = state.allJobs.filter(({ _id }) => _id !== id);
    },
    removeEvents: (state, action) => {
      const id = action.payload;
      state.allEvents = state.allEvents.filter(({ _id }) => _id !== id);
    },
    removeContacts: (state, action) => {
      const id = action.payload;
      state.allContactRequest = state.allContactRequest.filter(
        ({ _id }) => _id !== id
      );
    },
    removeApplication: (state, action) => {
      const id = action.payload;
      state.allApplications = state.allApplications.filter(
        ({ _id }) => _id !== id
      );
    },
  },
});

const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;
export default authReducer;
